import React from 'react';
import cc from 'classcat';

import styles from './styles.module.scss';

interface Props {
  title: string;
  subheading?: string;
}

const MiniHero = ({ title, subheading }: Props) => {
  return (
    <div
      className={cc({
        [styles.miniHero]: true,
        [styles.noSubheading]: !subheading,
      })}
    >
      <div className={styles.body}>
        <h1>{title}</h1>
        {subheading && <p>{subheading}</p>}
      </div>
    </div>
  );
};

export default MiniHero;
