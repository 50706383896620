import React from 'react';
import { GetServerSideProps } from 'next';
import { useQuery } from 'urql';

import MiniHero from '@/components/layout/MiniHero';
import { renderPageContent } from '@/lib/ContentRenderer';
import { getServerSidePropsWithGlobalData } from '@/lib/getServerSideProps';
import { extractFragment, extractItems } from '@/lib/GraphQLHelper';
import { withUrql } from '@/lib/urqlClient';
import { ErrorPage } from '@/pages/_error';

import query from './page.graphql';

import styles from './styles.module.scss';

interface Props {
  slug: string;
}

const GenericPage = ({ slug }: Props) => {
  const [{ data, error, fetching }] = useQuery<GenericEntryQuery>({
    query,
    variables: {
      slug,
    },
  });

  if (!fetching && !data?.entry) {
    return <ErrorPage statusCode={404} err={error} />;
  }

  const page = extractFragment<GenericEntryFragment>(data?.entry);
  const pageContent = extractItems<Craft.PageContent>(page?.pageContent);

  return (
    <article className={styles.newsArticle} data-testid="news-page">
      <MiniHero title={page?.title || ''} />
      <div className={styles.pageContent}>{pageContent?.map((row) => renderPageContent(row))}</div>
    </article>
  );
};

export const getServerSideProps: GetServerSideProps = async (ctx) =>
  await getServerSidePropsWithGlobalData(ctx, async (ctx) => {
    const { urqlClient, nextCtx } = ctx;
    const slug = nextCtx?.query.slug;

    await urqlClient
      .query<GenericEntryQuery, GenericEntryQueryVariables>(query, {
        slug,
      })
      .toPromise();

    return { props: { slug } };
  });

export default withUrql(GenericPage);
